import { Text, Box, BoxProps, SimpleGrid } from 'ui/core'
import Link from 'next/link'
import { formatTestID, siteMetadata } from '../../utils'
import HelpButton from './HelpButton'

interface HelpProps extends BoxProps {
  title: string,
  onLiveChat: () => void,
  testID?: string,
}

const Help = ({ testID, onLiveChat, title, ...props }: HelpProps) => {
  const whatsAppText = encodeURIComponent(
    ['Hello, I need help with my', title, 'at', window.location.href].join(' '),
  )

  return (
    <Box {...props} data-testid={testID}>
      <Text size="md">Need help?</Text>
      <SimpleGrid mt="sm" cols={{ base: 1, md: 3 }}>
        <HelpButton
          component={Link}
          href="/faqs"
          icon={{ name: 'help' }}
          title="Common questions"
          testID={formatTestID(testID, 'faqs')}
        />
        <HelpButton
          icon={{ name: 'message2', color: 'blue' }}
          title="Chat with us"
          onClick={onLiveChat}
          testID={formatTestID(testID, 'chat')}
        />
        <HelpButton
          component="a"
          target="_blank"
          href={`https://wa.me/${siteMetadata.contact.phoneNumber}?text=${whatsAppText}`}
          icon={{ name: 'brandWhatsapp', color: 'green' }}
          title="WhatsApp"
          testID={formatTestID(testID, 'whatsapp')}
        />
      </SimpleGrid>
    </Box>
  )
}

export default Help
