import dayjs from 'dayjs'
import { Text, TextProps, createPolymorphicComponent, Icon } from 'ui/core'
import dateTypes from './dateTypes'

type DateValue = Date

const getDateType = (props: Partial<DateTimeProps>) => {
  const { variant = 'date', format = 'default', friendlyTime = false } = props
  const type = dateTypes.find(
    (t) => t.variant === variant && t.format === format && t.friendlyTime === friendlyTime,
  )
  return !!type && type.type
}

export const formatDate = (v: DateValue, props?: Partial<DateTimeProps>) => {
  const date = dayjs(v)
  let now
  let dateText

  const type = getDateType(props || {})
  switch (type) {
    case 'timeOnly': {
      dateText = date.format('h:mma')
      break
    }
    case 'shortNormalDate': {
      now = dayjs()
      if (now.isSame(date, 'year')) {
        dateText = date.format('MMM D')
      } else {
        dateText = date.format('MMM D, YYYY')
      }
      break
    }
    case 'defaultNormalDateTime':
      dateText = date.format('MMM D, YYYY, h:mma')
      break
    case 'shortNormalDateTime':
      now = dayjs()
      if (now.isSame(date, 'year')) {
        dateText = date.format('MMM D, h:mma')
      } else {
        dateText = date.format('MMM D, YYYY, h:mma')
      }
      break
    default:
      dateText = date.format('MMM D, YYYY')
      break
  }
  return dateText
}

export interface DateTimeProps extends Omit<TextProps, 'variant'> {
  friendlyTime?: boolean,
  variant?: 'date' | 'dateTime',
  format?: 'default' | 'short' | 'timeOnly',
  value: DateValue,
  textVariant?: TextProps['variant'],
  testID?: string,
  withIcon?: boolean,
}

const DateTime = ({
  value,
  textVariant,
  format,
  friendlyTime,
  variant,
  testID,
  withIcon,
  style,
  ...rest
}: DateTimeProps) => (
  <Text
    variant={textVariant}
    data-testid={testID}
    style={withIcon ? { display: 'flex', alignItems: 'center', gap: '4px', ...style } : style}
    {...rest}
  >
    {withIcon && <Icon name="clock" color="gray" />}
    {formatDate(value, { variant, friendlyTime, format })}
  </Text>
)

export default createPolymorphicComponent<'div', DateTimeProps>(DateTime)
