const currencyFormatter = new Intl.NumberFormat('en-NG', {
  style: 'currency',
  currency: 'NGN',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const CURRENCY_SYMBOL
  = currencyFormatter.formatToParts(0).find(({ type }) => type === 'currency')?.value || ''

export const formatMoney = (amount: number) => currencyFormatter.format(amount)
