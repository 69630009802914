import {
  Text as MantineText,
  useMantineTheme,
  TextProps as MantineTextProps,
  createPolymorphicComponent,
  DefaultMantineColor,
  useMantineColorScheme,
} from '@mantine/core'
import { forwardRef } from 'react'

export interface TextProps extends MantineTextProps {
  error?: boolean,
  caption?: boolean,
  wordBreak?: 'break-word',
  color?: DefaultMantineColor,
  align?: 'left' | 'center' | 'right',
  transform?: 'uppercase' | 'capitalize' | 'lowercase',
}

const Text = forwardRef<HTMLDivElement, TextProps>(
  ({ error, caption, wordBreak, transform, align, style, ...rest }, ref) => {
    const theme = useMantineTheme()
    const { colorScheme } = useMantineColorScheme()
    const size = caption ? 'sm' : rest.size
    const _transform = caption ? 'uppercase' : transform
    const dark = colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[6]
    const color = error ? theme.colors.red[7] : caption ? dark : rest.color

    return (
      <MantineText
        style={{ wordBreak, textTransform: _transform, ...style }}
        ta={align}
        ref={ref}
        {...rest}
        size={size}
        c={color}
      />
    )
  },
)

export default createPolymorphicComponent<'div', TextProps>(Text)
