import pino from 'pino'
import { logflarePinoVercel } from 'pino-logflare'

const { stream, send } = logflarePinoVercel({
  apiKey: process.env.NEXT_PUBLIC_LOGFLARE_API_KEY as string,
  sourceToken: process.env.NEXT_PUBLIC_LOGFLARE_SOURCE_ID as string,
})

export const logger = pino(
  {
    browser: {
      transmit: {
        level: 'info',
        send: process.env.NODE_ENV === 'production' ? send : console.log,
      },
    },
    level: 'debug',
    base: {
      env: process.env.NODE_ENV,
      revision: process.env.VERCEL_GITHUB_COMMIT_SHA,
    },
  },
  stream,
)
