import { BareFetcher, Key, SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'

const defaultFetcher = async (input: RequestInfo, init: RequestInit | undefined = {}) =>
  fetch(input, init).then((res) => res.json())

const useSWR = <T>(
  key: Key,
  fetcher: BareFetcher<T> | null,
  options?: SWRConfiguration<T, Error>,
) => {
  const response = useSWRImmutable<T>(key, fetcher || defaultFetcher, options)
  return response
}

export default useSWR
