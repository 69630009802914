import { ReactNode } from 'react'
import { Text, Anchor } from 'ui/core'
import { siteMetadata } from '../../utils'

interface TextLinkProps {
  href: string,
  text: string,
  testID?: string,
}

const TextLink = ({ text, href, testID }: TextLinkProps) => (
  <Anchor href={href} target="_blank" data-testid={testID}>
    {text}
  </Anchor>
)

export interface ConsentProps {
  action: ReactNode,
  testID?: string,
}

const Consent = ({ action, testID }: ConsentProps) => (
  <Text size="sm" data-testid={testID}>
    By selecting{' '}
    <Text size="sm" fw="bold" component="span">
      {action}
    </Text>
    , I agree to {siteMetadata.name}&apos;s{' '}
    <TextLink text="Terms of Service" href="/terms-of-service" />
    , <TextLink text="Payments Terms of Service" href="/terms-of-service#payment" />, and
    acknowledge the <TextLink text="Privacy Policy" href="/privacy-policy" />.
  </Text>
)

export default Consent
