import { forwardRef } from 'react'
import { Anchor, AnchorProps, createPolymorphicComponent, Icon } from 'ui/core'
import { usePhoneNumber } from '../../hooks'

export interface PhoneNumberProps extends AnchorProps {
  value: string,
}

const PhoneNumber = forwardRef<HTMLAnchorElement, PhoneNumberProps>(
  ({ value, style, ...rest }, ref) => {
    const phoneNumber = usePhoneNumber(value, 'national')
    return (
      <Anchor
        href={`tel:${value}`}
        {...rest}
        style={{ display: 'flex', alignItems: 'center', gap: '4px', ...style }}
        ref={ref}
      >
        <Icon name="phone" />
        {phoneNumber}
      </Anchor>
    )
  },
)

export default createPolymorphicComponent<'a', PhoneNumberProps>(PhoneNumber)
