import dayjs from 'dayjs'
import { Text, TextProps, createPolymorphicComponent } from 'ui/core'
import relativeTime from 'dayjs/plugin/relativeTime'
import { DateTime, DateTimeProps } from '../DateTime'
import { capitalize } from '../../utils'

dayjs.extend(relativeTime)

export interface RelativeDateProps extends Omit<TextProps, 'variant'> {
  value: DateTimeProps['value'],
  dateTimeProps?: Omit<DateTimeProps, 'value'>,
  testID?: string,
}

const RelativeDate = ({ value, dateTimeProps, testID, ...rest }: RelativeDateProps) => (
  <Text {...rest} data-testid={testID}>
    {capitalize(dayjs().to(value))} (
    <DateTime
      {...dateTimeProps}
      format="short"
      friendlyTime
      inline
      component="span"
      value={value}
    />
    )
  </Text>
)

export default createPolymorphicComponent<'div', RelativeDateProps>(RelativeDate)
