import useSWR, { SWRConfiguration } from 'swr'
import { CollectionName, docFetcher, getDocumentRef } from '../../utils/helpers'
import { defaultConverter } from '../../utils/default-converter'
import { FirestoreDataConverter } from 'firebase/firestore'

type GetDocumentsProps<T> = {
  id?: string,
  collection: CollectionName,
  converter?: FirestoreDataConverter<T>,
}

export const useGetDocument = <T = any>(
  { collection, id, converter = defaultConverter }: GetDocumentsProps<T>,
  config?: SWRConfiguration<T>,
) =>
  useSWR<T>(
    id,
    id ? () => docFetcher(getDocumentRef<T>(collection, id).withConverter(converter)) : null,
    config,
  )
