import { urlFor } from '@resellam/sanity'
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import { Skeleton, Image as UIImage, ImageProps as UIImageProps } from 'ui/core'
import { FirebaseImage } from '../FirebaseImage'

const getSanityURL = (src: string, width?: string | number, height?: string | number) => {
  let builder = urlFor(src).crop('center')
  if (typeof width === 'number') {
    builder = builder.width(width * 2)
  }
  if (typeof height === 'number') {
    builder = builder.height(height * 2)
  }
  return builder.url()
}

export interface ImageProps extends Omit<NextImageProps, 'src'> {
  src?: string,
  path?: string,
  radius?: UIImageProps['radius'],
  objectFit?: UIImageProps['fit'],
  isLoading?: boolean,
  withLoader?: boolean,
  testID?: string,
}

const Image = ({
  src,
  path,
  objectFit = 'contain',
  radius,
  width,
  height,
  alt,
  style,
  isLoading,
  withLoader,
  fill,
  testID,
  ...rest
}: ImageProps) => {
  const commonProps = {
    radius,
    alt,
    'data-testid': testID,
  }

  if (path)
    return (
      <FirebaseImage
        {...commonProps}
        withLoader={withLoader}
        renderRoot={(props) => (
          <UIImage
            {...props}
            w={width}
            h={height}
            fit={objectFit}
          />
        )}
      />
    )

  if (isLoading)
    return <Skeleton {...commonProps} width={width} height={height} />

  if (src)
    return (
      <NextImage
        unoptimized
        {...commonProps}
        {...rest}
        width={width}
        height={height}
        fill={fill}
        style={{ objectFit, ...style }}
        src={
          src?.startsWith('https://cdn.sanity.io/images') ? getSanityURL(src, width, height) : src
        }
      />
    )

  return (
    <UIImage
      {...commonProps}
      w={width}
      h={height}
      fit={objectFit}
    />
  )
}

export default Image
