import { FacebookAuthProvider, signInWithPopup } from 'firebase/auth'
import { getAuth } from '@resellam/firebase'
import { logger } from '@resellam/logger'

export const signInWithFacebook = async () => {
  try {
    const provider = new FacebookAuthProvider()
    provider.addScope('email')
    provider.addScope('public_profile')
    return await signInWithPopup(getAuth(), provider)
  } catch (error: any) {
    logger.error(error)
    throw error
  }
}
