import { Group, GroupProps } from '@mantine/core'
import { IconButton, IconButtonProps } from '../IconButton'
import { formatTestID } from '../../utils'

export interface CardActionsProps extends GroupProps {
  actions: IconButtonProps[],
  testID?: string,
}

const CardActions = ({ actions, testID, ...rest }: CardActionsProps) => (
  <Group
    wrap="nowrap"
    align="center"
    data-testid={testID}
    {...rest}
    pos="absolute"
    right={0}
  >
    {actions.map((action) => (
      <IconButton key={action.name} testID={formatTestID(testID, action.name)} {...action} />
    ))}
  </Group>
)

export default CardActions
