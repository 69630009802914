import { useState, useMemo, createContext, ReactNode, useContext, useEffect } from 'react'
import { fetchAndActivate, getAll } from 'firebase/remote-config'
import { getRemoteConfig } from '@resellam/firebase'
import { logger } from '@resellam/logger'

type FeatureFlagValue = string | boolean | number

interface FeatureFlagContextValue {
  value: Record<string, FeatureFlagValue>,
}

const FeatureFlagContext = createContext<FeatureFlagContextValue | undefined>(undefined)

interface FeatureFlagProps {
  defaults: Record<string, FeatureFlagValue>,
  children: ReactNode,
}

const FeatureFlag = ({ children, defaults }: FeatureFlagProps) => {
  const [value, setValue] = useState(defaults)

  const init = async () => {
    if (process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'development')
      return
    try {
      const remoteConfig = getRemoteConfig()
      await fetchAndActivate(remoteConfig)
      const remoteFlags = getAll(remoteConfig)
      const newFlags = { ...defaults }

      Object.entries(remoteFlags).forEach(([key, val]) => {
        if (typeof defaults[key] === 'number') {
          newFlags[key] = val.asNumber()
        } else if (typeof defaults[key] === 'string') {
          newFlags[key] = val.asString()
        } else {
          newFlags[key] = val.asBoolean()
        }
      })

      setValue(newFlags)
    } catch (error) {
      logger.error(error, 'Error initializing feature flags')
    }
  }

  useEffect(() => {
    const remoteConfig = getRemoteConfig()
    remoteConfig.defaultConfig = defaults
    remoteConfig.settings.minimumFetchIntervalMillis = 43200000
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValue: FeatureFlagContextValue = useMemo(
    () => ({
      value,
    }),
    [value],
  )

  return <FeatureFlagContext.Provider value={contextValue}>{children}</FeatureFlagContext.Provider>
}

export default FeatureFlag

export const useFeatureFlag = () => {
  const context = useContext(FeatureFlagContext)
  if (context === undefined) {
    throw new Error('useFeatureFlag must be used within a FeatureFlagContext')
  }
  return context
}
