import { Stack, Divider, Title } from 'ui/core'
import { siteMetadata } from '../../utils'
import EmailForm from './EmailForm'
import PhoneForm from './PhoneForm'
import SocialAuth from './SocialAuth'

export interface AuthFormProps {
  onStart?: (provider: string) => void,
  onSuccess?: () => void,
  variant: 'user' | 'admin',
}

const AuthForm = ({ onSuccess, onStart, variant }: AuthFormProps) => (
  <Stack>
    <Title order={3}>Welcome to {siteMetadata.name}</Title>

    {variant === 'admin' && <EmailForm onSuccess={onSuccess} />}

    {variant === 'user' && (
      <>
        <PhoneForm onSuccess={onSuccess} onStart={() => onStart?.('phone')} />
        <Divider label="or continue with" labelPosition="center" my="sm" />
        <SocialAuth
          onSuccess={onSuccess}
          onStart={onStart}
          providers={['google.com', 'facebook.com']}
        />
      </>
    )}
  </Stack>
)

export default AuthForm
