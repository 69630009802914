import { forwardRef } from 'react'
import { Anchor, AnchorProps, createPolymorphicComponent, Icon } from 'ui/core'

export interface EmailProps extends AnchorProps {
  value: string,
}

const Email = forwardRef<HTMLAnchorElement, EmailProps>(({ value, style, ...rest }, ref) => (
  <Anchor
    href={`mailto:${value}`}
    {...rest}
    style={{ display: 'flex', alignItems: 'center', gap: '4px', ...style }}
    ref={ref}
  >
    <Icon name="mail" />
    {value}
  </Anchor>
))

export default createPolymorphicComponent<'a', EmailProps>(Email)
