import { MantineProvider, createTheme } from 'ui/core'
import { Notifications } from 'ui/notifications'
import { ModalsProvider } from 'ui/modals'
import { ReactNode } from 'react'
import dynamic from 'next/dynamic'
import { PopupProvider } from '../PopupContext'
import { theme as uiTheme } from '../../utils'

const DynamicRouteProgress = dynamic(() => import('../../components/RouteProgress/RouteProgress'), {
  ssr: false,
})

export interface CoreProviderProps {
  children: ReactNode,
  defaultColorScheme?: 'light' | 'dark',
}

const fontFamily
  = 'Inter,sans-serif,apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;'

const theme = createTheme({
  cursorType: 'pointer',
  primaryColor: 'blue',
  colors: {
    blue: [
      '#ddefff',
      '#cddff7',
      '#abcaf2',
      '#81afec',
      '#5795e6',
      uiTheme.colors.primary.blue,
      '#0360d9',
      '#0350b5',
      '#024091',
      '#02306d',
    ],
  },
  defaultRadius: 'md',
  fontFamily,
  headings: {
    fontFamily,
  },
  components: {
    Loader: { defaultProps: { type: 'oval' } },
    Avatar: { defaultProps: { radius: '50%' } },
    Checkbox: { defaultProps: { radius: 'xs' } },
    Menu: { defaultProps: { shadow: 'xl' } },
    Container: { defaultProps: { size: 'lg' } },
    Card: { defaultProps: { withBorder: true, shadow: 'none' } },
    CloseButton: { defaultProps: { radius: '50%' } },
    Select: { defaultProps: { withAsterisk: false } },
    MultiSelect: { defaultProps: { withAsterisk: false } },
    NativeSelect: { defaultProps: { withAsterisk: false } },
    RadioGroup: { defaultProps: { withAsterisk: false } },
    Textarea: { defaultProps: { withAsterisk: false } },
    TextInput: { defaultProps: { withAsterisk: false } },
    NumberInput: { defaultProps: { withAsterisk: false } },
    TimeInput: { defaultProps: { withAsterisk: false } },
    Autocomplete: { defaultProps: { withAsterisk: false } },
    Stepper: { defaultProps: { contentPadding: 'lg', size: 'sm' } },
    Modal: {
      styles: {
        title: {
          fontWeight: 500,
          fontSize: 'var(--mantine-font-size-lg)',
        },
      },
    },
  },
})

const CoreProvider = ({ defaultColorScheme, children }: CoreProviderProps) => (
  <MantineProvider theme={theme} defaultColorScheme={defaultColorScheme}>
    <Notifications position="top-center" limit={1} />
    <ModalsProvider>
      <PopupProvider>{children}</PopupProvider>
      <DynamicRouteProgress />
    </ModalsProvider>
  </MantineProvider>
)

export default CoreProvider
