import { Group, TextInput, Select } from 'ui/core'
import { logger } from '@resellam/logger'
import { useImperativeHandle, forwardRef } from 'react'
import { Address } from 'model'
import { useCreateDocument } from '@resellam/firebase'
import { PlaceAutocomplete } from '../PlaceAutocomplete'
import { PhoneNumberInput } from '../PhoneNumberInput'
import { addressSchema } from '../../utils'
import { LGAs, STATES } from './AddressForm.utils'
import { useFormUI } from '../../hooks'

export interface AddressFormProps {
  onSuccess?: (address: Address) => void,
  onError?: () => void,
  onStart?: () => void,
  onCancel?: () => void,
  testID?: string,
}

const AddressForm = forwardRef(
  ({ onStart, onSuccess, onError, onCancel, testID }: AddressFormProps, ref) => {
    const [createAddress, createAddressState] = useCreateDocument<Address>()

    const schema = addressSchema()

    const { form, render } = useFormUI({
      schema,
      initialValues: {
        firstName: '',
        lastName: '',
        street: '',
        phoneNumber: '',
        lga: '',
        state: 'Lagos',
        country: 'Nigeria',
        googlePlaceId: '',
      },
    })

    useImperativeHandle(ref, () => form, [form])

    const formSubmit = async (values: typeof form.values) => {
      if (form.validate().hasErrors)
        return
      try {
        onStart?.()
        const result = await createAddress({
          collection: 'addresses',
          data: schema.parse(values),
        })
        if (result) {
          form.resetDirty()
          onSuccess?.(result)
        }
      } catch (error) {
        logger.error(error, 'Error saving address')
        onError?.()
      }
    }

    return render({
      testID,
      isLoading: createAddressState.isRunning,
      onSubmit: formSubmit,
      onCancel,
      children: (
        <>
          <Group grow>
            <TextInput
              {...form.getInputProps('firstName')}
              placeholder="First name"
              label="First name"
            />
            <TextInput
              {...form.getInputProps('lastName')}
              placeholder="Last name"
              label="Last name"
            />
          </Group>
          <PhoneNumberInput
            {...form.getInputProps('phoneNumber')}
            label="Phone number"
            placeholder="Phone number"
          />
          <PlaceAutocomplete
            error={form.getInputProps('street').error}
            label="Street"
            onChange={(place) => {
              form.setFieldValue('street', place?.name || '')
              form.setFieldValue('googlePlaceId', place?.id || '')
            }}
          />
          <Group grow>
            <Select
              {...form.getInputProps('state')}
              label="State"
              data={STATES}
            />
            <Select {...form.getInputProps('lga')} label="LGA" data={LGAs} />
          </Group>
        </>
      ),
    })
  },
)

export default AddressForm
