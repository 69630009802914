import { useAuth } from '@resellam/auth'
import { Button, Text, Icon, Stack } from 'ui/core'
import { useModals } from 'ui/modals'
import { SectionHeader } from '../Section'
import { usePhoneNumber } from '../../hooks'
import DeleteAccountForm from './DeleteAccountForm'
import EmailAddressForm from './EmailAddressForm'
import UpdatePhoneNumber from './UpdatePhoneNumber'
import EditableUserSection from './EditableUserSection'

interface UserAccountProps {
  onUpdateEmailAddress?: () => void,
  onUpdatePhoneNumber?: () => void,
  onDeleteAccount?: () => void,
}

const UserAccount = ({
  onUpdateEmailAddress,
  onUpdatePhoneNumber,
  onDeleteAccount,
}: UserAccountProps) => {
  const auth = useAuth()
  const modals = useModals()
  const phoneNumber = usePhoneNumber(auth.user?.phoneNumber, 'national')

  const openPhoneForm = () => {
    const id = modals.openModal({
      title: `${auth.user?.phoneNumber ? 'Change' : 'Add'} Phone number`,
      children: (
        <UpdatePhoneNumber onSuccess={() => modals.closeModal(id)} onStart={onUpdatePhoneNumber} />
      ),
    })
  }

  const openEmailForm = () => {
    const id = modals.openModal({
      title: `${auth.user?.email ? 'Change' : 'Add'} Email address`,
      children: (
        <EmailAddressForm onSuccess={() => modals.closeModal(id)} onStart={onUpdateEmailAddress} />
      ),
    })
  }

  const openDeleteAccountForm = () => {
    const id = modals.openModal({
      title: 'Delete Account',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <DeleteAccountForm
          onStart={onDeleteAccount}
          onCancel={() => modals.closeModal(id)}
          onDelete={() => {
            modals.closeModal(id)
            auth.signout()
          }}
        />
      ),
    })
  }

  return (
    <Stack>
      <EditableUserSection
        title="Email address"
        value={auth.user?.email}
        onAdd={auth.isAdmin ? undefined : openEmailForm}
        onEdit={auth.isAdmin ? undefined : openEmailForm}
      />
      <EditableUserSection
        title="Phone number"
        value={phoneNumber}
        onAdd={openPhoneForm}
        onEdit={openPhoneForm}
      />
      <div>
        <SectionHeader title="Delete account" />
        <Text color="gray">
          We&apos;re sorry you want to delete your account! By pressing the button below, you can
          start a full account deletion.
        </Text>
        <Button
          mt="md"
          variant="outline"
          color="red"
          leftSection={<Icon name="trash" />}
          onClick={openDeleteAccountForm}
        >
          Delete my account
        </Button>
      </div>
    </Stack>
  )
}

export default UserAccount
