import {
  RecaptchaVerifier,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
} from 'firebase/auth'
import { getAuth } from '@resellam/firebase'
import { logger } from '@resellam/logger'

export const signInWithPhoneNumber = async (
  phoneNumber: string,
  recaptchaVerifier: RecaptchaVerifier,
) => {
  try {
    const confirmationResult = await firebaseSignInWithPhoneNumber(
      getAuth(),
      phoneNumber,
      recaptchaVerifier,
    )
    return confirmationResult
  } catch (error) {
    logger.error(error)
    throw error
  }
}
