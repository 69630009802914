import { createPolymorphicComponent, Skeleton, MantineRadius, Box, Loader } from 'ui/core'
import { getStorage } from '@resellam/firebase'
import { ref as storageRef } from 'firebase/storage'
import { useDownloadURL } from 'react-firebase-hooks/storage'
import { forwardRef, memo } from 'react'

export interface FirebaseImageProps {
  path?: string,
  withLoader?: boolean,
  loaderType?: 'skeleton' | 'oval',
  width?: number | string,
  height?: number | string,
  radius?: MantineRadius,
}

const FirebaseImage = forwardRef<HTMLImageElement, FirebaseImageProps>(
  ({ path, width, height, radius, withLoader, loaderType = 'skeleton', ...rest }, ref) => {
    const [value, isLoading] = useDownloadURL(path ? storageRef(getStorage(), path) : undefined)
    return withLoader && isLoading ? (
      loaderType === 'oval' ? (
        <Loader />
      ) : (
        <Skeleton width={width} height={height} radius={radius} />
      )
    ) : (
      <Box
        component="img"
        src={value}
        w={width}
        height={height}
        {...rest}
        ref={ref}
      />
    )
  },
)

const MemoizedFirebaseImage = memo(FirebaseImage, (prev, next) => prev.path === next.path)

export default createPolymorphicComponent<'img', FirebaseImageProps>(MemoizedFirebaseImage)
