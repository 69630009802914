import { Flex, FlexProps } from 'ui/core'
import { useId } from 'react'
import { formatTestID } from '../../utils'
import { DetailItem, DetailItemProps } from '../DetailItem'

export interface DetailListProps extends FlexProps {
  items: DetailItemProps[],
  itemProps?: Pick<DetailItemProps, 'layout'>,
  testID?: string,
}

const DetailList = ({ items, testID, itemProps, ...rest }: DetailListProps) => {
  const listId = useId()

  return (
    <Flex
      data-testid={testID}
      direction="column"
      gap="md"
      {...rest}
    >
      {items.map((item, index) => (
        <DetailItem
          key={`${listId}-${index}`}
          testID={formatTestID(testID, item.label)}
          {...itemProps}
          {...item}
        />
      ))}
    </Flex>
  )
}

export default DetailList
