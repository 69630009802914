import { forwardRef, ReactNode } from 'react'
import { Input, InputProps } from '@mantine/core'

export interface SelectInputProps extends InputProps {
  placeholder?: string,
  value?: string,
  error?: boolean,
  data: { label: ReactNode, value: string }[],
  onChange?: (value: string | null) => void,
}

const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
  ({ data, onChange, placeholder, error, value, ...rest }, ref) => (
    <Input
      ref={ref}
      component="select"
      value={value || ''}
      onChange={(event: any) => onChange?.(event.target.value)}
      styles={(theme) => ({
        input: {
          color: value ? undefined : error ? theme.colors.red[6] : theme.colors.gray[4],
          borderColor: error ? theme.colors.red[6] : undefined,
        },
      })}
      {...rest}
    >
      <option disabled value="">
        {placeholder}
      </option>
      {data.map((opt) => (
        <option key={opt.value} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </Input>
  ),
)

export default SelectInput
