import { addDoc, FirestoreDataConverter } from 'firebase/firestore'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { CollectionName, getCollectionRef } from '../../utils/helpers'
import { defaultConverter } from '../../utils/default-converter'
import { getAuth } from 'firebase/auth'

interface CreateDocument<T> {
  collection: CollectionName,
  data: Partial<T>,
  converter?: FirestoreDataConverter<T>,
}

const createDocument = async <T>(options: CreateDocument<T>): Promise<T> => {
  const { collection, data } = options
  const collectionRef = getCollectionRef(collection).withConverter(
    options.converter || defaultConverter,
  )
  const ref = await addDoc<T>(collectionRef, data as T)
  return { id: ref.id, ...data } as unknown as T
}

export const useCreateDocument = <T>(): [
  (input: CreateDocument<T>) => Promise<T | null>,
  TaskState<T>,
] => {
  const [taskFn, taskState] = useTask<T>()
  const run = (data: CreateDocument<T>) =>
    taskFn(() =>
      createDocument({
        ...data,
        data: {
          createdById: getAuth().currentUser?.uid,
          ...data.data,
        },
      }),
    )
  return [run, taskState]
}
