import { Payment } from '../Payment'

export enum ShippingMethod {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
}

interface Shipping {
  price?: number,
  addressId?: string,
  date?: Date,
  payment?: Payment,
}

export default Shipping
