import { ReactNode } from 'react'
import { Container, Modal, ModalProps, ScrollArea } from 'ui/core'
import ModalHeader from '../ModalHeader'

const FullscreenModalContent = ({
  title,
  onClose,
  children,
}: {
  children: ReactNode,
  title: ReactNode,
  onClose: () => void,
}) => {
  const scrollHeight
    = typeof window === 'undefined' ? 500 : window.innerHeight - 96
  return (
    <>
      <ModalHeader container title={title} onBack={onClose} />
      <Container mt="md" px={0}>
        <ScrollArea offsetScrollbars h={scrollHeight} scrollbars="y">
          {children}
        </ScrollArea>
      </Container>
    </>
  )
}

export interface FullscreenModalProps extends ModalProps {
  testID?: string,
}

const FullscreenModal = ({
  onClose,
  children,
  title,
  testID,
  ...rest
}: FullscreenModalProps) => (
  <Modal
    {...rest}
    fullScreen
    closeOnEscape={false}
    withCloseButton={false}
    withOverlay={false}
    onClose={onClose}
    transitionProps={{ transition: 'fade' }}
    data-testid={testID}
  >
    <FullscreenModalContent title={title} onClose={onClose}>
      {children}
    </FullscreenModalContent>
  </Modal>
)

export default FullscreenModal
