import { Avatar, useMantineColorScheme } from 'ui/core'
import { useAuth } from '@resellam/auth'
import { initials } from '../../utils'
import { FirebaseImage } from '../FirebaseImage'

const UserAvatar = () => {
  const { user } = useAuth()
  const { colorScheme } = useMantineColorScheme()

  return (
    <FirebaseImage
      path={user?.photo?.path}
      renderRoot={(props) => (
        <Avatar
          {...props}
          color={colorScheme === 'light' ? 'white' : 'dark'}
          size="md"
          radius="xl"
          style={(theme) => ({
            border: `1px solid ${
              colorScheme === 'light' ? theme.colors.blue[5] : theme.colors.gray[5]
            }`,
          })}
        />
      )}
    >
      {initials(user?.firstName, user?.lastName)}
    </FirebaseImage>
  )
}

export default UserAvatar
