import { ReactNode } from 'react'
import { Title, Text, Group, Button, Stack, ButtonProps } from 'ui/core'
import { BottomSheet } from '../BottomSheet'

export type ActionSheetVariant = 'confirmation'
export type ActionSheetButtonProps = ButtonProps & { title?: string, onClick?: () => void }

export interface ActionSheetProps {
  opened: boolean,
  withinPortal?: boolean,
  variant?: ActionSheetVariant,
  title?: string,
  message?: string,
  children?: ReactNode,
  close?: ActionSheetButtonProps,
  confirm?: ActionSheetButtonProps,
  onDismiss: () => void,
}

const ActionSheet = ({
  opened,
  withinPortal,
  variant,
  title,
  message,
  children,
  close,
  onDismiss,
  confirm,
}: ActionSheetProps) => {
  const header = title ? <Title order={3}>{title}</Title> : null
  const subheader = message ? <Text size="md">{message}</Text> : null
  let content = children

  if (variant === 'confirmation') {
    content = (
      <Group grow>
        <Button
          variant="default"
          {...close}
          onClick={() => {
            close?.onClick?.()
            onDismiss?.()
          }}
        >
          {close?.title || 'No'}
        </Button>
        <Button
          {...confirm}
          onClick={() => {
            confirm?.onClick?.()
            onDismiss?.()
          }}
        >
          {confirm?.title || `Yes, ${title}`}
        </Button>
      </Group>
    )
  }

  return (
    <BottomSheet opened={opened} withinPortal={withinPortal} onClose={onDismiss}>
      <Stack>
        {header}
        {subheader}
        {content}
      </Stack>
    </BottomSheet>
  )
}

export default ActionSheet
