import { Text, Button } from 'ui/core'
import Link from 'next/link'
import { Page } from '../Page'
import { Header } from '../Header'

const NotFoundPage = () => (
  <Page>
    <Header title="Page not found" />
    <Text>You just hit a page that doesn&apos;t exist... the sadness. &#128577;</Text>
    <Button
      mt="xl"
      variant="outline"
      component={Link}
      href="/"
      replace
    >
      Go to homepage
    </Button>
  </Page>
)

export default NotFoundPage
