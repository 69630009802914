import { Group, LoadingOverlay, Stack, Box, ButtonProps, Button, TextInput } from 'ui/core'
import { zodResolver } from 'ui/form'
import { logger } from '@resellam/logger'
import { ReactNode, useEffect } from 'react'
import { User } from 'model'
import { z } from 'zod'
import { useUpdateUser } from '../../services'
import { useForm } from '../../hooks'

const schema = z.object({
  firstName: z.string().trim().min(1, { message: 'First name is required' }),
  lastName: z.string().trim().min(1, { message: 'Last name is required' }),
})

export interface PersonalInfoFormProps {
  user?: User,
  submit?: ButtonProps,
  children?: ReactNode,
  onSuccess?: () => void,
  onError?: () => void,
  onStart?: () => void,
}

const PersonalInfoForm = ({
  user,
  submit,
  children,
  onStart,
  onSuccess,
  onError,
}: PersonalInfoFormProps) => {
  const [updateUser, updateUserState] = useUpdateUser()

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validate: zodResolver(schema),
  })

  useEffect(() => {
    if (!user)
      return
    form.setValues({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const formSubmit = async (values: typeof form.values) => {
    if (form.validate().hasErrors)
      return
    try {
      onStart?.()
      await updateUser(schema.parse(values))
      form.resetDirty()
      onSuccess?.()
    } catch (error) {
      logger.error(error)
      onError?.()
    }
  }

  return (
    <Box component="form" style={{ position: 'relative' }} onSubmit={form.onSubmit(formSubmit)}>
      <LoadingOverlay visible={updateUserState.isRunning} />
      <Stack>
        <Group grow>
          <TextInput
            required
            placeholder="First name"
            label="Your first name"
            {...form.getInputProps('firstName')}
          />
          <TextInput
            required
            placeholder="Last name"
            label="Your last name"
            {...form.getInputProps('lastName')}
          />
        </Group>
        {children}
        <Button
          mt="md"
          type="submit"
          variant="outline"
          fullWidth
          disabled={!form.isDirty()}
          {...submit}
        >
          {submit?.children || 'Save'}
        </Button>
      </Stack>
    </Box>
  )
}

export default PersonalInfoForm
