import { useForm as useUIForm } from 'ui/form'

const useForm: typeof useUIForm = (props) => {
  const form = useUIForm({
    ...props,
    mode: 'controlled',
  })

  return form
}

export default useForm
