import { useMemo, useState } from 'react'
import { useDebouncedValue } from 'ui/hooks'
import { ComboboxProps, Loader, Icon, Combobox, useCombobox, TextInput } from 'ui/core'
import { Place } from 'model'
import { useApi } from '../../hooks'
import PlaceResultItem from './PlaceResultItem'

interface PlaceAutocompleteProps extends Omit<ComboboxProps, 'data' | 'onChange'> {
  onChange?: (place: Place | null) => void,
  label?: string,
  error?: string,
  required?: boolean,
  testID?: string,
}

const PlaceAutocomplete = ({
  size,
  required,
  testID,
  label,
  error,
  onChange,
  ...rest
}: PlaceAutocompleteProps) => {
  const [value, setValue] = useState('')

  const [query] = useDebouncedValue(value, 200)

  const { data, isLoading } = useApi<{ data: Place[] }>(query ? `/places?query=${query}` : null, {
    host: process.env.NEXT_PUBLIC_CONTENT_API_HOST,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  })

  const autocompleteData = useMemo(() => data?.data.slice(0, 5), [data?.data])

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const empty = !isLoading && !autocompleteData?.length && value

  const options = (autocompleteData || []).map((item) => (
    <Combobox.Option value={item.name} key={item.id}>
      <PlaceResultItem place={item} />
    </Combobox.Option>
  ))

  return (
    <Combobox
      withinPortal
      data-lpignore="true"
      data-testid={testID}
      {...rest}
      size={size}
      onOptionSubmit={(optionValue) => {
        const place = autocompleteData?.find((item) => item.name === optionValue)
        if (place) {
          setValue(optionValue)
          onChange?.(place)
        }
        combobox.closeDropdown()
      }}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          error={error}
          size={size}
          label={label}
          required={required}
          value={value}
          leftSection={<Icon name="mapPin" />}
          onChange={(event) => {
            setValue(event.currentTarget.value)
            combobox.resetSelectedOption()
            combobox.openDropdown()
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          rightSection={isLoading && <Loader size="sm" />}
        />
      </Combobox.Target>
      <Combobox.Dropdown hidden={!(Boolean(autocompleteData?.length) || empty)}>
        <Combobox.Options>
          {options}
          {empty && <Combobox.Empty>No results found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default PlaceAutocomplete
