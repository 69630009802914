import { Group, IconButton, Badge, GroupProps } from 'ui/core'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { capitalize } from '../../utils'
import { FilterField } from './types'

export interface FilterTagsProps extends GroupProps {
  fields: FilterField[],
}

const FilterTags = ({ fields, ...rest }: FilterTagsProps) => {
  const router = useRouter()

  const filterFields = new Set(fields.map((field) => field.name))

  const tags = useMemo(
    () =>
      Object.entries(router.query)
        .filter(([key]) => filterFields.has(key))
        .map(([key, val]) => ({ key, title: key, value: capitalize(val as string) })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.query],
  )

  const removeTag = (tagKey: string) => {
    const { [tagKey]: key, ...query } = router.query
    router.replace({ query }, undefined, { shallow: true })
  }

  return (
    <Group gap="xs" {...rest}>
      {tags.map((tag) => (
        <Badge
          key={tag.key}
          variant="outline"
          color="gray"
          size="lg"
          style={{ paddingRight: '8px' }}
          rightSection={(
            <IconButton
              title="Remove"
              variant="transparent"
              name="x"
              size="sm"
              onClick={() => removeTag(tag.key)}
            />
          )}
        >
          {tag.value}
        </Badge>
      ))}
    </Group>
  )
}

export default FilterTags
