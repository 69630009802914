import imageUrlBuilder from '@sanity/image-url'
import { createClient } from '@sanity/client'

export const sanityConfig = {
  /**
   * Find your project ID and dataset in `sanity.json` in your studio project.
   * These are considered “public”, but you can use environment variables
   * if you want differ between local dev and production.
   *
   * https://nextjs.org/docs/basic-features/environment-variables
   **/
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET as string,
  token: process.env.NEXT_PUBLIC_SANITY_TOKEN as string,
  projectId: (process.env.NEXT_PUBLIC_SANITY_PROJECT_ID as string) || 'projectId',
  apiVersion: '2022-04-03', // Learn more: https://www.sanity.io/docs/api-versioning
  /**
   * Set useCdn to `false` if your application require the freshest possible
   * data always (potentially slightly slower and a bit more expensive).
   * Authenticated request (like preview) will always bypass the CDN
   **/
  useCdn: process.env.NODE_ENV === 'production',
  ignoreBrowserTokenWarning: true,
}

const client = createClient(sanityConfig)

const builder = imageUrlBuilder(client)

export const urlFor = (source: string) => builder.image(source)

export const getSanityClient = () => client
