import useTask from '@resellam/hooks/hooks/use-task'
import { sendSignInLinkToEmail } from '@resellam/auth'
import { useState } from 'react'
import { EmailForm as BasicEmailForm } from '../EmailForm'
import EmailSent from './EmailSent'

export interface EmailFormProps {
  onStart?: () => void,
  onSuccess?: () => void,
}

const EmailForm = ({ onStart, onSuccess }: EmailFormProps) => {
  const [email, setEmail] = useState('')
  const [signInWithEmailTaskFn, signInWithEmailTaskState] = useTask<boolean | null>()

  const submit = (submittedEmail: string) =>
    signInWithEmailTaskFn(async () => {
      setEmail(submittedEmail)
      onStart?.()
      await sendSignInLinkToEmail(submittedEmail)
      onSuccess?.()
      return true
    })

  if (signInWithEmailTaskState.value)
    return <EmailSent email={email} />

  return (
    <BasicEmailForm showHelperText onSubmit={submit} loading={signInWithEmailTaskState.isRunning} />
  )
}
export default EmailForm
