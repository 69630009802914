import { Icon, IconProps, Button } from 'ui/core'
import { forwardRef } from 'react'
import { formatTestID } from '../../../utils'

export interface HelpButtonProps {
  icon: IconProps,
  title: string,
  component?: any,
  href?: string,
  target?: string,
  onClick?: () => void,
  testID?: string,
}

const HelpButton = forwardRef<HTMLButtonElement, HelpButtonProps>(
  ({ title, icon, href, target, testID, component, onClick }, ref) => (
    <Button
      component={component}
      variant="default"
      style={{ height: 62 }}
      ref={ref}
      href={href}
      target={target}
      onClick={onClick}
      data-testid={testID}
      leftSection={(
        <Icon
          color={icon.color}
          name={icon.name}
          size={28}
          testID={formatTestID(testID, 'icon', icon.name)}
        />
      )}
    >
      {title}
    </Button>
  ),
)

export default HelpButton
