import { Slider, SliderProps, Group, Text, FormField, Icon } from 'ui/core'
import { ReactNode } from 'react'
import { formatTestID } from '../../utils'
import classes from './BatterySlider.module.css'

const colors = (() => {
  let red = 255
  let green = 0
  const step = 5
  const colorCodes = [`rgb(${red}, ${green}, 0)`]
  for (let i = 0; i < 255; i += step) {
    red -= step
    green += step
    colorCodes.push(`rgb(${red}, ${green}, 0)`)
  }
  return colorCodes
})()

const batteryMin = 49

export interface BatterySliderProps extends Omit<SliderProps, 'label'> {
  label?: ReactNode,
  error?: ReactNode,
  showHelpText?: boolean,
  testID?: string,
}

const BatterySlider = ({
  label,
  error,
  disabled,
  showHelpText,
  value,
  testID,
  onChange,
}: BatterySliderProps) => (
  <FormField
    label={label}
    error={error}
    helpText={
      showHelpText ? (
        <Group gap={0}>
          <Text>1. Go to Settings.</Text>
          <Text>2. Tap &quot;Battery&quot;.</Text>
          <Text>3. Tap &quot;Battery Health&quot;.</Text>
        </Group>
      ) : undefined
    }
    style={{ maxWidth: '420px' }}
    testID={testID}
  >
    <Slider
      thumbSize={8}
      value={value}
      onChange={onChange}
      labelAlwaysOn
      min={batteryMin}
      max={100}
      disabled={disabled}
      thumbChildren={<Icon name="gripVertical" size="md" />}
      label={(currentValue) => `${currentValue}%`}
      labelTransitionProps={{
        transition: 'skew-down',
        duration: 150,
        timingFunction: 'ease',
      }}
      data-testid={formatTestID(testID, 'slider')}
      classNames={{
        root: classes.root,
        track: classes.track,
        trackContainer: classes.trackContainer,
        bar: classes.bar,
        thumb: classes.thumb,
      }}
      styles={{
        bar: {
          backgroundColor: colors[value ? value - batteryMin : 0],
        },
      }}
    />
  </FormField>
)

export default BatterySlider
