import { useAuth } from '@resellam/auth'
import { useMemo } from 'react'

const useIsLoading = ({ dependencies }: { dependencies: boolean[] }) => {
  const { loading } = useAuth()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => loading || dependencies.some((dep) => dep), [loading, ...dependencies])
}

export default useIsLoading
