import { ReactNode } from 'react'
import {
  Button,
  Stack,
  Group,
  LoadingOverlay,
  Box,
  ButtonProps,
} from 'ui/core'
import { useForm, zodResolver } from 'ui/form'
import { ZodSchema } from 'zod'

interface TFormProps<TUseFormValues extends Record<string, any>> {
  schema: ZodSchema<Record<string, any>>,
  initialValues: TUseFormValues,
  onValuesChange?: (values: TUseFormValues) => void,
}

interface TRenderProps<TUseFormValues extends Record<string, any>> {
  children: ReactNode,
  isLoading?: boolean,
  onSubmit: (values: TUseFormValues) => void,
  onCancel?: () => void,
  cancelButtonProps?: Partial<ButtonProps>,
  submitButtonProps?: Partial<ButtonProps>,
  testID?: string,
}

const useFormUI = <TUseFormValues extends Record<string, any>>({
  schema,
  initialValues,
  onValuesChange,
}: TFormProps<TUseFormValues>) => {
  const form = useForm<TUseFormValues>({
    mode: 'controlled',
    initialValues,
    validate: zodResolver(schema),
    onValuesChange,
  })

  const render = ({
    children,
    isLoading,
    onSubmit,
    onCancel,
    cancelButtonProps,
    submitButtonProps,
    testID,
  }: TRenderProps<TUseFormValues>) => (
    <Box
      component="form"
      style={{ position: 'relative' }}
      onSubmit={form.onSubmit(onSubmit)}
      data-testid={testID}
    >
      <Stack>{children}</Stack>
      <Group grow justify="right" mt="xl">
        {onCancel && (
          <Button variant="default" onClick={onCancel} {...cancelButtonProps}>
            {cancelButtonProps?.children || 'Cancel'}
          </Button>
        )}
        <Button type="submit" {...submitButtonProps}>{submitButtonProps?.children || 'Save'}</Button>
      </Group>
      <LoadingOverlay visible={isLoading} />
    </Box>
  )

  return { form, render }
}

export default useFormUI
