import { Box, Button, AppShell, Container } from 'ui/core'
import { ReactNode } from 'react'
import { Brand } from '../Brand'
import { Image } from '../Image'
import classes from './AuthShell.module.css'
import { AppHeader } from '../AppLayout'

export interface AuthShellProps {
  brandTitle?: string,
  contentWidth?: string,
  children: ReactNode,
  onHelp?: () => void,
}

const AuthShell = ({ contentWidth = '520px', onHelp, children, brandTitle }: AuthShellProps) => (
  <AppShell layout="alt" withBorder={false} aside={{ width: 460, breakpoint: 'sm' }}>
    <AppShell.Main>
      <Box pos="relative">
        <Box pt="xl" style={{ position: 'absolute', top: 0, right: 0, left: 0 }}>
          <AppHeader
            leftSection={<Brand withTitle title={brandTitle} />}
            rightSection={
              onHelp && (
                <Button size="compact-sm" variant="subtle" onClick={onHelp}>
                  Need help?
                </Button>
              )
            }
          />
        </Box>
      </Box>
      <Container
        h="100vh"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box pos="relative" style={{ maxWidth: contentWidth }}>
          {children}
        </Box>
      </Container>
    </AppShell.Main>
    <AppShell.Aside className={classes.aside}>
      <Image
        alt="Sell gadgets for cash"
        src="/images/auth-illustration.png"
        width={460}
        height={398}
      />
    </AppShell.Aside>
  </AppShell>
)

export default AuthShell
