import { Text } from 'ui/core'

export interface NoResultsProps {
  query: string,
}

const NoResults = ({ query }: NoResultsProps) => (
  <Text
    color="gray"
    align="center"
  >
    No items matching &quot;{query}&quot;
  </Text>
)

export default NoResults
