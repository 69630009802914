import { FirestoreDataConverter, getDocs } from 'firebase/firestore'
import { SWRConfiguration } from 'swr'
import useSWR from 'swr/immutable'
import { CollectionName, firebaseQueryBuilder, FirebaseQueryProps } from '../../utils/helpers'
import { defaultConverter } from '../../utils/default-converter'

type QueryDocumentsProps<T> = FirebaseQueryProps & {
  skip?: boolean,
  collection: CollectionName,
  converter?: FirestoreDataConverter<T>,
}

export const useQueryDocuments = <T>(
  { collection, where, orderBy, limit, skip, converter = defaultConverter }: QueryDocumentsProps<T>,
  config?: SWRConfiguration,
) => {
  return useSWR<T[]>(
    !skip ? [collection, where, orderBy, limit] : null,
    async () => {
      const snap = await getDocs<T>(
        firebaseQueryBuilder<T>(collection, { where, orderBy, limit }).withConverter(converter),
      )
      return snap.docs.map((document) => document.data())
    },
    config,
  )
}
