import { ReactNode } from 'react'
import {
  Icon,
  Group,
  Text,
  Button,
  PaperProps,
  Paper,
  UnstyledButton,
  BackgroundImage,
} from 'ui/core'

export interface MorePhotosCardProps extends PaperProps {
  height?: number,
  src?: string,
  onClick: () => void,
  children?: ReactNode,
}

const MorePhotosCard = ({ height, children, src, onClick }: MorePhotosCardProps) => (
  <Paper<any>
    component={UnstyledButton}
    p={0}
    radius={0}
    onClick={onClick}
    style={{
      height,
      position: 'relative',
    }}
  >
    {src && (
      <BackgroundImage
        src={src}
        style={{
          height,
          filter: 'blur(1px)',
        }}
      />
    )}
    <Group justify="center" style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
      <Button
        component="span"
        size="compact-md"
        variant="default"
        radius="xl"
      >
        <Icon name="plus" />
        <Text fw={500}>{children}</Text>
      </Button>
    </Group>
  </Paper>
)

export default MorePhotosCard
