import {
  Loader,
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
} from '@mantine/core'
import { forwardRef } from 'react'

export interface SelectProps extends MantineSelectProps {
  loading?: boolean,
}

const Select = forwardRef<HTMLInputElement, SelectProps>(
  ({ loading, leftSection, ...rest }, ref) => {
    return (
      <MantineSelect
        {...rest}
        ref={ref}
        leftSection={loading ? <Loader size="sm" /> : leftSection}
      />
    )
  },
)

export default Select
