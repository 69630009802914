import { AppShellProps } from 'ui/core'
import { AppHeader, AppFooter, AppLayout as CoreAppLayout, BrandLink } from 'core/components'
import RightMenu from './RightMenu'

export interface AppLayoutProps extends AppShellProps {}

const AppLayout = ({ children }: AppLayoutProps) => (
  <CoreAppLayout
    footerProps={{ children: <AppFooter /> }}
    headerProps={{
      children: (
        <AppHeader leftSection={<BrandLink title="Admin" />} rightSection={<RightMenu />} />
      ),
    }}
  >
    {children}
  </CoreAppLayout>
)

export default AppLayout
