import { Enum } from 'model'
import { CollectionName, useQueryDocuments } from '@resellam/firebase'
import { useMemo } from 'react'
import { EnumSelect, EnumSelectProps } from '../EnumSelect'

export interface FirestoreEnumSelectProps extends Omit<EnumSelectProps, 'data'> {
  collection: CollectionName,
  extraData?: Enum[],
  testID?: string,
}

const FirestoreEnumSelect = ({ collection, extraData = [], ...rest }: FirestoreEnumSelectProps) => {
  const { data = [] } = useQueryDocuments<Enum>({
    collection,
    orderBy: useMemo(() => [{ field: 'order', direction: 'asc' }], []),
  })
  return <EnumSelect {...rest} data={[...data, ...extraData]} />
}

export default FirestoreEnumSelect
