import { logger } from '@resellam/logger'
import { useAuth } from '@resellam/auth'
import { Avatar, Button, Center, Icon, Stack } from 'ui/core'
import { useRef } from 'react'
import { FirebaseImage } from '../FirebaseImage'
import { Dropzone } from '../Dropzone'
import { initials, compressFile } from '../../utils'
import { useUploadUserPhoto } from '../../services'

interface UploadPhotomProps {
  onStart?: () => void,
  onSuccess?: () => void,
  onError?: () => void,
}

const UploadPhoto = ({ onStart, onSuccess, onError }: UploadPhotomProps) => {
  const { user } = useAuth()
  const openDropzoneRef = useRef<() => void>(null)
  const [uploadUserPhoto, uploadUserPhotoState] = useUploadUserPhoto()

  const updatePhoto = async (file: File) => {
    try {
      onStart?.()
      const photo = await compressFile(file, {
        width: 400,
        height: 400,
        resize: 'cover',
      })
      await uploadUserPhoto({ photo })
      onSuccess?.()
    } catch (error) {
      onError?.()
      logger.error(error)
    }
  }

  return (
    <Center>
      <Stack align="center">
        <Dropzone
          unstyled
          multiple={false}
          loading={uploadUserPhotoState.isRunning}
          openRef={openDropzoneRef}
          onDrop={([photo]: File[]) => updatePhoto(photo)}
        >
          <FirebaseImage
            path={user?.photo?.path}
            renderRoot={(props) => <Avatar {...props} size={150} />}
          >
            {initials(user?.firstName, user?.lastName)}
          </FirebaseImage>
        </Dropzone>
        <Button
          size="compact-md"
          variant="outline"
          leftSection={<Icon name="photoUp" />}
          onClick={() => openDropzoneRef?.current?.()}
        >
          Upload photo
        </Button>
      </Stack>
    </Center>
  )
}

export default UploadPhoto
