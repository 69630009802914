import {
  isSignInWithEmailLink,
  signInWithEmailLink as firebaseSignInWithEmailLink,
} from 'firebase/auth'
import { getAuth } from '@resellam/firebase'
import { logger } from '@resellam/logger'
import { FIREBASE_SEND_SIGNIN_LINK_TO_EMAIL_KEY } from '../utils/constants'

export const signInWithEmailLink = async (getEmail: () => Promise<string>) => {
  try {
    const emailLink = window.location.href
    if (!isSignInWithEmailLink(getAuth(), emailLink))
      throw new Error('Email link is invalid')
    let email = window.localStorage.getItem(FIREBASE_SEND_SIGNIN_LINK_TO_EMAIL_KEY)
    if (!email) {
      email = await getEmail()
    }
    const credential = await firebaseSignInWithEmailLink(getAuth(), email, emailLink)
    window.localStorage.removeItem(FIREBASE_SEND_SIGNIN_LINK_TO_EMAIL_KEY)
    return credential
  } catch (error) {
    logger.error(error)
    throw error
  }
}
