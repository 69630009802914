import { useAuth } from '@resellam/auth'
import { ReactNode } from 'react'
import { Stack } from 'ui/core'
import { useNotifications } from '../../hooks'
import { SectionHeader } from '../Section'
import UploadPhoto from './UploadPhoto'
import PersonalInfoForm from './PersonalInfoForm'

interface ProfileFormProps {
  children?: ReactNode,
  onUploadPhoto?: () => void,
  onUpdatePersonalInfo?: () => void,
}

const ProfileForm = ({ children, onUploadPhoto, onUpdatePersonalInfo }: ProfileFormProps) => {
  const { user } = useAuth()
  const notifications = useNotifications()

  const notify = (success?: boolean) => {
    notifications.show({
      variant: success ? 'success' : 'error',
      action: 'update',
      entity: 'profile',
    })
  }

  return (
    <Stack>
      <UploadPhoto
        onStart={onUploadPhoto}
        onSuccess={() => notify(true)}
        onError={() => notify(false)}
      />
      <div>
        <SectionHeader title="Personal info" />
        <PersonalInfoForm
          user={user}
          onStart={onUpdatePersonalInfo}
          onSuccess={() => notify(true)}
          onError={() => notify(false)}
        />
      </div>
      {children}
    </Stack>
  )
}

export default ProfileForm
