import { Modal, ModalProps } from 'ui/core'

export interface BottomSheetProps extends ModalProps {}

const BottomSheet = ({ transitionProps, ...rest }: BottomSheetProps) => (
  <Modal
    withCloseButton={false}
    yOffset={0}
    xOffset={0}
    radius="lg"
    {...rest}
    transitionProps={{ transition: 'slide-up', duration: 200, ...transitionProps }}
    styles={{
      inner: {
        top: 'unset',
      },
      content: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      body: {
        paddingBottom: 'var(--mantine-spacing-xl)',
      },
    }}
  />
)

export default BottomSheet
