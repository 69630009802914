import type { ReactNode } from 'react'
import { SettingsLayout as CoreSettingsLayout } from 'core/components'
import { Icon } from 'ui/core'
import AppLayout from './AppLayout'

export interface SettingsLayoutProps {
  children: ReactNode,
}

const navLinks = [
  {
    route: '/settings/profile',
    label: 'Profile',
    icon: <Icon name="userCircle" />,
  },
  {
    route: '/settings/account',
    label: 'Account',
    icon: <Icon name="settings" />,
  },
]

const SettingsLayout = ({ children }: SettingsLayoutProps) => (
  <AppLayout>
    <CoreSettingsLayout navLinks={navLinks}>{children}</CoreSettingsLayout>
  </AppLayout>
)

export default SettingsLayout
