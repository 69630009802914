import { deleteDoc } from 'firebase/firestore'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { CollectionName, getDocumentRef } from '../../utils/helpers'
import { defaultConverter } from '../../utils/default-converter'

type DeleteDocument = {
  collection: CollectionName,
  id: string,
}

const deleteDocument = async (collection: CollectionName, id: string): Promise<boolean> => {
  const docRef = getDocumentRef(collection, id).withConverter(defaultConverter)
  await deleteDoc(docRef)
  return true
}

export const useDeleteDocument = (): [
  (input: DeleteDocument) => Promise<boolean | null>,
  TaskState<boolean>,
] => {
  const [taskFn, taskState] = useTask<boolean>()
  const run = (data: DeleteDocument) => taskFn(() => deleteDocument(data.collection, data.id))
  return [run, taskState]
}
