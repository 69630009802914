import { forwardRef, ReactNode } from 'react'
import { createPolymorphicComponent, Button, ButtonProps } from '@mantine/core'
import classes from './buttonCard.module.css'

type ButtonCardProps = ButtonProps & {
  children: ReactNode,
  testID?: string,
}

const ButtonCard = forwardRef<HTMLButtonElement, ButtonCardProps>(
  ({ children, className, testID, ...rest }, ref) => (
    <Button
      variant="default"
      h="auto"
      p="md"
      className={[classes.root, className].filter(Boolean).join(' ')}
      classNames={{ label: classes.label }}
      data-testid={testID}
      {...rest}
      ref={ref}
    >
      {children}
    </Button>
  ),
)

export default createPolymorphicComponent<'button', ButtonCardProps>(ButtonCard)

export type {
  ButtonCardProps,
}
