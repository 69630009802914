export type PaymentStatus = 'SUCCESS' | 'FAILURE'

export enum PaymentMethod {
  CARD = 'CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
}

export enum PaymentType {
  NOW = 'NOW',
  ON_DELIVERY = 'ON_DELIVERY',
}

interface Payment {
  amount?: number,
  type?: PaymentType,
  method?: PaymentMethod,
  status?: PaymentStatus,
  paystack?: {
    reference?: string,
  },
}

export default Payment
