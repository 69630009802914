import { sendSignInLinkToEmail as firebaseSendSignInLinkToEmail } from 'firebase/auth'
import { getAuth } from '@resellam/firebase'
import { logger } from '@resellam/logger'
import {
  LOGIN_PAGE_URL,
  FINISH_SIGN_IN_PAGE_URL,
  FIREBASE_SEND_SIGNIN_LINK_TO_EMAIL_KEY,
  ORIGIN_QUERY_PARAM,
} from '../utils/constants'
import { getRedirectURL } from '../utils/helpers'

export const sendSignInLinkToEmail = async (email: string) => {
  try {
    await firebaseSendSignInLinkToEmail(getAuth(), email, {
      url: `${window.location.origin}${getRedirectURL(
        FINISH_SIGN_IN_PAGE_URL,
        window.location.pathname === LOGIN_PAGE_URL ? undefined : ORIGIN_QUERY_PARAM,
      )}`,
      handleCodeInApp: true,
    })
    window.localStorage.setItem(FIREBASE_SEND_SIGNIN_LINK_TO_EMAIL_KEY, email)
  } catch (error) {
    logger.error(error)
    throw error
  }
}
