import { ReactNode } from 'react'
import { Text, Button, Card, Stack, CardProps } from 'ui/core'

export interface TumbleweedProps extends Omit<CardProps, 'children'> {
  callToAction?: {
    title: string,
    onClick: () => void,
  },
  children?: ReactNode,
  dataName?: string,
  prefix?: string,
  tumbleweedContent?: string,
  variant?: 'field' | 'card',
}

const Tumbleweed = ({
  children,
  prefix = 'No',
  tumbleweedContent,
  callToAction,
  dataName,
  variant,
  ...rest
}: TumbleweedProps) => {
  if (variant === 'card') {
    return (
      <Card {...rest}>
        <Stack align="center">
          <Text
            color="gray"
            mb={children ? 'md' : undefined}
          >{`${prefix} ${tumbleweedContent}`}
          </Text>
          {children}
          {callToAction && (
            <Button size="compact-md" variant="outline" onClick={callToAction.onClick}>
              {callToAction.title}
            </Button>
          )}
        </Stack>
      </Card>
    )
  }

  return <Text>{dataName ? `(${dataName} unknown)` : '(No data)'}</Text>
}

export default Tumbleweed
