import { Menu, Divider, Icon } from 'ui/core'
import { useAuth } from '@resellam/auth'
import { RightMenu as CoreRightMenu } from 'core/components'
import Link from 'next/link'

const RightMenu = () => {
  const { signout } = useAuth()

  return (
    <CoreRightMenu>
      <Menu.Item
        href="/orders"
        leftSection={<Icon name="shoppingBag" color="gray" />}
        component={Link}
      >
        Orders
      </Menu.Item>
      <Menu.Item
        href="/requests"
        leftSection={<Icon name="send" color="gray" />}
        component={Link}
      >
        Requests
      </Menu.Item>
      <Menu.Item href="/fields" leftSection={<Icon name="braces" color="gray" />} component={Link}>
        Fields
      </Menu.Item>
      <Divider />
      <Menu.Item
        href="/settings/profile"
        component={Link}
        leftSection={<Icon name="settings" color="gray" />}
      >
        Settings
      </Menu.Item>
      <Menu.Item leftSection={<Icon name="logout" color="gray" />} onClick={signout}>
        Log out
      </Menu.Item>
    </CoreRightMenu>
  )
}

export default RightMenu
