import { ReactNode } from 'react'
import { Title, Group } from 'ui/core'
import { formatTestID } from '../../utils'

interface SectionHeaderProps {
  title: string,
  action?: ReactNode,
  testID?: string,
}

export const SectionHeader = ({ title, action, testID }: SectionHeaderProps) => (
  <Group
    mb="xs"
    wrap="nowrap"
    justify="space-between"
    align="center"
    data-testid={testID}
  >
    <Title order={4} data-testid={formatTestID(testID, 'title')}>
      {title}
    </Title>
    {action}
  </Group>
)
