import { forwardRef, useEffect } from 'react'
import { useForm } from '@mantine/form'
import { Group, Input, InputWrapperProps } from '@mantine/core'
import { SelectInput } from '../Input'

const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i)

const formatTimeUnit = (num: number) => (num > 9 ? `${num}` : `0${num}`)

const hours = range(9, 17).map((num) => ({
  value: num.toString(),
  label: `${num > 12 ? num - 12 : num}`,
}))

const minutes = range(0, 59)
  .filter((val) => val % 5 === 0)
  .map((num) => ({
    value: num.toString(),
    label: formatTimeUnit(num),
  }))

export interface TimePickerProps extends Omit<InputWrapperProps, 'onChange' | 'children'> {
  value?: Date,
  onChange?: (value: Date) => void,
}

const TimePicker = forwardRef<HTMLDivElement, TimePickerProps>(
  ({ onChange, value, size, error, ...rest }, ref) => {
    const form = useForm<{ hour?: number, minute?: number }>({
      initialValues: {},
    })

    const hasHour = typeof form.values.hour === 'number'
    const hasMinute = typeof form.values.minute === 'number'

    useEffect(() => {
      if (!hasHour || !hasMinute)
        return
      const { hour, minute } = form.values as Required<typeof form.values>
      const date = new Date()
      date.setHours(hour, minute)
      onChange?.(date)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.hour, form.values.minute])

    return (
      <Input.Wrapper
        ref={ref}
        size={size}
        error={error}
        {...rest}
      >
        <Group grow>
          <SelectInput
            size={size}
            error={!!error}
            data={hours}
            placeholder="Hour"
            value={hasHour ? `${form.values.hour}` : undefined}
            onChange={(val) => form.setFieldValue('hour', Number(val))}
          />
          <SelectInput
            size={size}
            error={!!error}
            data={minutes}
            placeholder="Minute"
            value={hasMinute ? `${form.values.minute}` : undefined}
            onChange={(val) => form.setFieldValue('minute', Number(val))}
          />
          <Input
            readOnly
            size={size}
            disabled={!form.values.hour}
            value={form.values.hour ? (form.values.hour < 12 ? 'AM' : 'PM') : 'AM'}
            styles={{
              input: {
                textAlign: 'center',
              },
            }}
          />
        </Group>
      </Input.Wrapper>
    )
  },
)

export default TimePicker
