import { updateDoc } from 'firebase/firestore'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { getDocumentRef, getAuth, defaultConverter } from '@resellam/firebase'
import { User } from 'model'
import { updateProfile } from 'firebase/auth'
import { useSWRConfig } from 'swr'

type UpdateUser = {
  firstName?: string,
  lastName?: string,
  agent?: {
    categories?: string[],
  },
}

const updateUser = async (userId: string, data: UpdateUser): Promise<User> => {
  const docRef = getDocumentRef('users', userId).withConverter(defaultConverter)
  await updateDoc(docRef, data)
  return { id: userId, ...data }
}

export const useUpdateUser = (): [(data: UpdateUser) => Promise<User | null>, TaskState<User>] => {
  const { mutate } = useSWRConfig()
  const [taskFn, taskState] = useTask<User>()
  const run = (data: UpdateUser) =>
    taskFn(async () => {
      const { currentUser } = getAuth()
      if (!currentUser?.uid)
        throw new Error('Cannot update unauthenticated User')
      if (data.firstName && data.lastName) {
        await updateProfile(currentUser, {
          displayName: `${data.firstName} ${data.lastName}`,
        })
      }
      const user = await updateUser(currentUser?.uid, data)
      mutate(user.id, (val: User = {} as User) => ({ ...val, ...data }))
      return user
    })
  return [run, taskState]
}
