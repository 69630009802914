import { Container, Group, IconButton, Text } from 'ui/core'
import { ReactNode } from 'react'

export interface ModalHeaderProps {
  onBack: () => void,
  title: ReactNode,
  container?: boolean,
}

const ModalHeader = ({ onBack, title, container }: ModalHeaderProps) => {
  const content = (
    <Group align="center">
      <IconButton
        title="Back"
        name="chevronLeft"
        onClick={onBack}
      />
      <Text size="lg" fw={500}>{title}</Text>
    </Group>
  )

  return container ? <Container px={0}>{content}</Container> : content
}

export default ModalHeader
