import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { getAuth } from '@resellam/firebase'
import { verifyBeforeUpdateEmail } from 'firebase/auth'
import { getRedirectURL } from '@resellam/auth'

type UpdateUser = {
  email: string,
}

export const useUpdateUserEmail = (): [
  (data: UpdateUser) => Promise<boolean | null>,
  TaskState<boolean>,
] => {
  const [taskFn, taskState] = useTask<boolean>()
  const run = (data: UpdateUser) =>
    taskFn(async () => {
      const { currentUser } = getAuth()
      if (!currentUser)
        throw new Error('Cannot update unauthenticated User')
      await verifyBeforeUpdateEmail(currentUser, data.email, {
        url: `${window.location.origin}${getRedirectURL(window.location.pathname)}`,
        handleCodeInApp: true,
      })
      return true
    })
  return [run, taskState]
}
