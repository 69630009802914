import { forwardRef } from 'react'
import { TextInput, TextInputProps } from 'ui/core'

export interface PhoneNumberInputProps extends TextInputProps {}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(({ ...rest }, ref) => (
  <TextInput
    maxLength={11}
    type="tel"
    label="Phone number"
    placeholder="Phone number"
    {...rest}
    ref={ref}
  />
))

export default PhoneNumberInput
