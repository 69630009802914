import { AuthProvider as CoreAuthProvider } from '@resellam/auth'
import { PUBLIC_PAGES } from 'helpers/constants'
import { ReactNode } from 'react'

export interface AuthProviderProps {
  children: ReactNode,
}

const AuthProvider = ({ children }: AuthProviderProps) => (
  <CoreAuthProvider publicPages={PUBLIC_PAGES}>{children}</CoreAuthProvider>
)

export default AuthProvider
