import { Stack, Anchor, Text, Icon } from 'ui/core'

interface EmailSentProps {
  email?: string,
}

const EmailSent = ({ email }: EmailSentProps) => (
  <Stack align="center">
    <Icon name="mail" size={80} thickness={1} />
    <Text size="lg">Check your inbox</Text>
    <Text>
      We sent an email to{' '}
      <Anchor inline component="span">
        {email}
      </Anchor>{' '}
      to verify it&apos;s yours. Please click the link in the email to continue.
    </Text>
  </Stack>
)
export default EmailSent
