import { FacebookAuthProvider, GoogleAuthProvider, PhoneAuthProvider } from 'firebase/auth'
import { ROOT_URL } from './constants'

export const getRedirectURL = (pageUrl: string, queryParam?: string) => {
  const { location } = window
  let url = ROOT_URL
  if (location.pathname !== ROOT_URL) {
    url = location.href.substring(location.href.indexOf(location.pathname))
  }
  return queryParam ? `${pageUrl}?${queryParam}=${encodeURIComponent(url)}` : pageUrl
}

const isAnAbsoluteUrl = (url: string) => /:?\/\//i.test(url)
const isLoginUrl = (url: string) => url === 'login' || url === '/login'

export const navigateToOrigin = () => {
  const url = new URL(window.location.href)
  const origin = url.searchParams.get('origin')
  if (!origin || isAnAbsoluteUrl(origin) || isLoginUrl(origin)) {
    window.location.replace(ROOT_URL)
  } else {
    window.location.replace(origin)
  }
}

export type FirebaseAuthProviderID =
  | typeof GoogleAuthProvider.PROVIDER_ID
  | typeof FacebookAuthProvider.PROVIDER_ID
  | typeof PhoneAuthProvider.PROVIDER_ID
