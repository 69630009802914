import { Flex, Badge, MantineSize, FlexProps } from 'ui/core'
import { Discount } from 'model'
import { Money } from '../Money'
import { useDiscountedPrice } from '../../hooks'

export interface DiscountedPriceProps {
  price: number,
  quantity?: number,
  discount?: Discount,
  direction?: 'row' | 'column',
  align?: FlexProps['align'],
  size?: MantineSize,
  testID?: string,
}

const sizes: MantineSize[] = ['xs', 'sm', 'md', 'lg', 'xl']

const DiscountedPrice = ({
  price,
  discount,
  quantity = 1,
  direction = 'column',
  size = 'md',
  align,
  testID,
}: DiscountedPriceProps) => {
  const { label: discountLabel, price: discountedPrice } = useDiscountedPrice({
    price,
    discount,
  })

  const smallerSize = sizes[sizes.indexOf(size) - 1]
  const smallerAlign = align ?? direction === 'column' ? 'flex-end' : 'center'

  return discountedPrice ? (
    <Flex
      gap="xs"
      direction="column"
      align={align}
      data-testid={testID}
    >
      <Money
        inline
        value={discountedPrice * quantity}
        fw="bold"
        size={size}
      />
      <Flex direction={direction} align={smallerAlign} gap={8}>
        <Money
          inline
          value={price * quantity}
          size={smallerSize}
          style={{ textDecoration: 'line-through' }}
        />
        <Badge
          color="red"
          variant="filled"
          size={size}
          style={align ? undefined : { alignSelf: 'flex-end' }}
        >
          {discountLabel}
        </Badge>
      </Flex>
    </Flex>
  ) : (
    <Flex direction="column" align={align} data-testid={testID}>
      <Money
        value={price * quantity}
        fw="bold"
        size={size}
        data-testid={testID}
      />
    </Flex>
  )
}

export default DiscountedPrice
