import { Select, SelectProps } from 'ui/core'
import { Enum } from 'model'

export interface EnumSelectProps extends Omit<SelectProps, 'data' | 'value' | 'onChange'> {
  value?: Enum,
  data: Enum[],
  onChange?: (val: Enum | null) => void,
  testID?: string,
}

const EnumSelect = ({ value, data, testID, onChange, ...rest }: EnumSelectProps) => {
  const change = (val: string | null) => {
    if (!val) {
      onChange?.(null)
    } else {
      onChange?.(data.find(({ id }) => id === val) || { id: val, name: val })
    }
  }

  return (
    <Select
      {...rest}
      value={value?.id}
      data={data.map((item) => ({ label: item.name, value: item.id }))}
      onChange={change}
      data-testid={testID}
    />
  )
}

export default EnumSelect
