import { FormField, FormFieldProps, SegmentedControl, Skeleton } from 'ui/core'
import { Enum } from 'model'
import { formatTestID } from '../../utils'

export interface EnumChipsSelectProps extends Omit<FormFieldProps, 'value' | 'onChange'> {
  value?: Enum,
  data: Enum[],
  loading?: boolean,
  disabled?: boolean,
  onChange?: (value: Enum) => void,
  testID?: string,
}

const EnumChipsSelect = ({
  value,
  data,
  onChange,
  loading,
  disabled,
  testID,
  ...rest
}: EnumChipsSelectProps) => {
  const change = (newValue: string) => {
    onChange?.(data.find(({ id }) => id === newValue) || { id: newValue, name: newValue })
  }

  return (
    <FormField {...rest} testID={testID}>
      {loading ? (
        <Skeleton height={42} radius="md" />
      ) : (
        <SegmentedControl
          fullWidth
          disabled={disabled}
          value={value?.id || ''}
          onChange={change}
          data={data.map(({ id, name }) => ({ label: name, value: id }))}
          data-testid={formatTestID(testID, 'segmented-control')}
        />
      )}
    </FormField>
  )
}

export default EnumChipsSelect
