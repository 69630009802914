import { parsePhoneNumber } from 'awesome-phonenumber'
import { z } from 'zod'

const REGION_CODE = 'NG'

const phoneNumberSchema = () =>
  z
    .string()
    .trim()
    .min(11, { message: 'Phone number is required' })
    .transform((val) => parsePhoneNumber(val, REGION_CODE).getNumber('e164'))

export const enumSchema = (options?: { required_error?: string }) =>
  z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    { required_error: options?.required_error || 'Select an option' },
  )

export const addressSchema = (options?: { required_error?: string }) =>
  z.object(
    {
      street: z.string().trim().min(1, { message: 'Street is required' }),
      lga: z.string().min(1, { message: 'LGA is required' }),
      state: z.string().min(1, { message: 'State is required' }),
      country: z.string().min(1, { message: 'Country is required' }).default('Nigeria'),
      phoneNumber: phoneNumberSchema(),
      firstName: z.string().trim().min(1, { message: 'First name is required' }),
      lastName: z.string().trim().min(1, { message: 'Last name is required' }),
      googlePlaceId: z.string({ required_error: 'Google place ID required' }),
    },
    options,
  )
