import { useState } from 'react'
import { logger } from '@resellam/logger'

export type TaskStatus = 'idle' | 'running' | 'success' | 'error'

export interface DerivedTaskState {
  isIdle: boolean,
  isError: boolean,
  isSuccess: boolean,
  isRunning: boolean,
}

export interface TaskState<T, E = Error> extends DerivedTaskState {
  value: T | null,
  error: E | null,
  status: TaskStatus,
}

const transform = <T, E>(state: Omit<TaskState<T, E>, keyof DerivedTaskState>) => ({
  ...state,
  isIdle: state.status === 'idle' || state.status !== 'running',
  isError: state.status === 'error',
  isSuccess: state.status === 'success',
  isRunning: state.status === 'running',
})

const useTask = <T, E extends Error = Error>(
  defaultValue?: Partial<TaskState<T, E>>,
): [(task: () => Promise<T | null>) => Promise<T | null>, TaskState<T, E>] => {
  const [state, setState] = useState<TaskState<T, E>>(
    transform({
      status: defaultValue?.status || 'idle',
      value: defaultValue?.value || null,
      error: defaultValue?.error || null,
    }),
  )

  const perform = async (task: () => Promise<T | null>): Promise<T | null> => {
    setState((val) => transform({ ...val, status: 'running' }))

    try {
      const value = await task()
      setState((val) =>
        transform({
          ...val,
          value,
          error: null,
          status: 'success',
        }),
      )
      return value
    } catch (error) {
      setState((val) =>
        transform({
          ...val,
          error: error as E,
          value: null,
          status: 'error',
        }),
      )
      logger.error(error, 'Task error')
      throw error
    }
  }

  return [perform, state]
}

export default useTask
