import { Group, Text, UnstyledButton, useMantineColorScheme } from 'ui/core'
import Link from 'next/link'
import { theme as uiTheme, siteMetadata } from '../../utils'
import Logo, { LogoProps } from './Logo'

export interface BrandProps extends LogoProps {
  title?: string,
  withTitle?: boolean,
}

const Brand = ({ withTitle, title, color, width = 44, height = 32 }: BrandProps) => {
  const { colorScheme } = useMantineColorScheme()
  const localColor = color || (colorScheme === 'dark' ? 'white' : uiTheme.colors.primary.blue)
  const textColor = color || (colorScheme === 'dark' ? 'white' : 'black')

  return (
    <Group gap="sm">
      <Logo width={width} height={height} color={localColor} />
      {(withTitle || title) && (
        <Text size="xl" color={textColor} fw={600}>
          {title || siteMetadata.name}
        </Text>
      )}
    </Group>
  )
}

export const BrandLink = (props: BrandProps) => (
  <UnstyledButton
    component={Link}
    href="/"
    p={0}
    aria-label="Resellam"
  >
    <Brand {...props} />
  </UnstyledButton>
)

export default Brand
