type OrderBy = {
  field: string,
  direction: 'asc' | 'desc',
}

type WhereFilterOp = '==' | 'in' | 'match' | '>' | '!='

type Where = {
  field: string,
  operator: WhereFilterOp,
  value: unknown,
}

export type Query = {
  where?: (Where | string)[],
  orderBy?: OrderBy[],
  slice?: string,
}

export const buildQuery = (query?: Query) => {
  const where = (query?.where || [])
    .reduce(
      (acc, item) => [
        ...acc,
        typeof item === 'string' ? item : `${item.field} ${item.operator} ${item.value}`,
      ],
      [''],
    )
    .join(' && ')
  const order = (query?.orderBy || [])
    .reduce((acc, item) => [...acc, `order(${item.field} ${item.direction})`], [''])
    .join(' | ')
  return { where, order, slice: query?.slice || '' }
}

export type DocumentType = 'brand' | 'product' | 'category' | 'productPart'
