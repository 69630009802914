import { Group, Button, Stack, Popover, Select, Icon, TextInput } from 'ui/core'
import { useEffect, useMemo } from 'react'
import { useForm } from 'ui/form'
import { useRouter } from 'next/router'
import { useDisclosure } from 'ui/hooks'
import { FilterField, FilterFieldInputText, FilterFieldInputSelect } from './types'

export interface FilterProps {
  fields: FilterField[],
}

const FilterFieldComponent = ({ type, input }: FilterField) => {
  if (type === 'text') {
    return (
      <TextInput
        autoFocus={false}
        {...(input as FilterFieldInputText)}
        onChange={(event) => input.onChange?.(event.target.value)}
      />
    )
  }
  if (type === 'select') {
    return (
      <Select
        autoFocus={false}
        {...(input as FilterFieldInputSelect)}
        onChange={(val) => input.onChange?.(val)}
      />
    )
  }
  return null
}

const Filter = ({ fields }: FilterProps) => {
  const router = useRouter()

  const [isOpen, handlers] = useDisclosure(false)

  const form = useForm<Record<string, unknown>>({
    initialValues: router.query,
  })

  const filterFields = new Set(fields.map((field) => field.name))

  const hasQuery = useMemo(
    () => !!Object.entries(router.query).filter(([key]) => filterFields.has(key)).length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.query],
  )

  useEffect(() => {
    Object.entries(router.query).forEach(
      ([key, val]) => filterFields.has(key) && form.setFieldValue(key, val || null),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  const close = () => {
    handlers.close()
  }

  const apply = () => {
    const query = Object.entries(form.values).reduce((acc, [key, val]) => {
      if (val) {
        acc[key] = `${val}`
      } else {
        delete acc[key]
      }
      return acc
    }, router.query as Record<string, string>)
    router.replace({ query }, undefined, { shallow: true })
    close()
  }

  return (
    <Popover
      opened={isOpen}
      onClose={close}
      width={260}
      position="bottom-end"
      trapFocus
      closeOnClickOutside={false}
    >
      <Popover.Target>
        <Button
          size="sm"
          variant={hasQuery ? 'outline' : 'default'}
          leftSection={<Icon name="filter" color={hasQuery ? 'blue' : 'gray'} />}
          onClick={() => handlers.toggle()}
        >
          Filter
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap="md">
          {fields.map((field) => (
            <FilterFieldComponent
              key={field.name}
              name={field.name}
              type={field.type}
              input={{ ...field.input, ...form.getInputProps(field.name) }}
            />
          ))}
        </Stack>
        <Group mt="md" justify="flex-end">
          <Button variant="default" onClick={close}>
            Close
          </Button>
          <Button onClick={apply}>Filter</Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  )
}

export default Filter
