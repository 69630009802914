import { Group, Title, Stack, IconButton, GroupProps, Skeleton } from 'ui/core'
import { ReactNode } from 'react'
import { useBreakpoint } from '../../hooks'
import classes from './Header.module.css'

interface HeaderProps extends GroupProps {
  title?: string,
  loading?: boolean,
  children?: ReactNode,
  onClickBack?: () => void,
}

const Header = ({ title, loading, children, onClickBack, ...rest }: HeaderProps) => {
  const { isSm } = useBreakpoint()

  let titleUI = null

  if (title || loading)
    titleUI = loading ? (
      <Skeleton width={120} height={30} radius="xl" />
    ) : (
      <Title order={1} className={classes.title}>
        {title}
      </Title>
    )

  const back = onClickBack ? (
    <IconButton name="chevronLeft" title="Back" onClick={onClickBack} />
  ) : null

  return isSm && back && children ? (
    <Stack mb="md" gap="sm">
      <Group justify="space-between">
        {back}
        {children}
      </Group>
      {titleUI}
    </Stack>
  ) : (
    <Group mb="md" justify="space-between" {...rest}>
      <Group>
        {back}
        {titleUI}
      </Group>
      {children}
    </Group>
  )
}

export default Header
